import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldErrorOtherComponent } from './field-error-other/field-error-other.component';
import { FieldErrorDisplayComponent } from './field-error-display/field-error-display.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TitlesplitPipe } from './titlesplit.pipe';
import { SavingSpinnerComponent } from './saving-spinner/saving-spinner.component';


@NgModule({
  declarations: [
    FieldErrorOtherComponent,
    FieldErrorDisplayComponent,
    TitlesplitPipe,
    SavingSpinnerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    FieldErrorOtherComponent,
    FieldErrorDisplayComponent,
    TitlesplitPipe,
    SavingSpinnerComponent
  ]
})
export class SharedModule { }
