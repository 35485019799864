export enum Role {
    DEV = 'dev',
    ADMIN = 'admin',
    AGENCY_ADMIN = "AGENCY ADMIN",
    BILLER = "BILLER",
    FINANCE = "FINANCE",
    DASHBOARD_MANAGER = "DASHBOARD MANAGER",
    ADMITTING_CLINICIAN = "ADMITTING CLINICIAN",
    DPCS = "DPCS",
    MARKETING = "MARKETING"
  }
  