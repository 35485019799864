import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User, UserClass } from './user-model';
// import { tokenGetter } from '../app.module';

@Injectable({
    providedIn: 'root'
  })

export class AuthModel {

    token: string;
    decodedToken: string;
    currentUser: User = new UserClass();

    constructor(private sessionKey: string, private jwtHelper: JwtHelperService = new JwtHelperService()){

        // this.token = localStorage.getItem('sessionKey');
        this.token = this.sessionKey;
        this.decodedToken = this.jwtHelper.decodeToken(this.token);
        // debugger
        this.currentUser.userCode = this.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
        this.currentUser.tenantId = this.decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/primarygroupsid"];
        this.currentUser.agencyId = this.decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid"];
        this.currentUser.userId = this.decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"];
        this.currentUser.employeeId = this.decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/primarysid"];
        this.currentUser.roleId = this.decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        this.currentUser.firstName = this.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname']
        this.currentUser.lastName = this.decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'];
        this.currentUser.fullName = this.decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
        this.currentUser.expiration = this.decodedToken["exp"];

        this.currentUser.isDev = this.currentUser.tenantId == "1" && this.currentUser.agencyId == "5"

    }


}

export interface TokenResponse
{
    accessToken: string;
    refreshToken: string;
}

export interface IdleRefreshPayload
{
    userName: string,
    password: string,
    accessToken?: string;
    refreshToken?: string;
}