import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titlesplit'
})

export class TitlesplitPipe implements PipeTransform {

  transform(value: string): string {

    let str = value.split("_")
    let isAbbr = str.every(e => e.length === 1); //is Abbreviation
    let res;

    if(isAbbr){

      res = str.map(s => s.toUpperCase());
    }else{
      res = str.map(s => {return s.replace(s[0],s[0].toUpperCase())});
    }

    let strJoin = isAbbr ? res.join("") : res.join(" ");

    return strJoin;
  }

}
