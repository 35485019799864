import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Role } from '../enums/role.enums';


@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient) { }

  public async isDpcsOnly(){
    
    let userRoles: string[] = JSON.parse(localStorage.getItem("user-roles"));
    // const isAgencyAdmin = await this.hasUserRoles([Role.AGENCY_ADMIN]);
    // const isDpcs = await this.hasUserRoles([Role.DPCS]);

    const isDpcsRole = userRoles.includes(Role.DPCS) && 
      userRoles.includes(Role.AGENCY_ADMIN) && 
      !userRoles.some((role => role.includes(Role.MARKETING)));

    return isDpcsRole;
  }

  private async hasUserRoles(roles: string[]): Promise<boolean>{
    let userRoles = await this.setStorageUserRoles();
    let roleMatched = 0;

       roles.forEach((role) => {
          if((userRoles).includes(role)){
            roleMatched++;
          }
        });

    return (roles.length === roleMatched);
  }

  public async setStorageUserRoles(){
    let userRoles: string[] = JSON.parse(localStorage.getItem("user-roles"));

    if(!userRoles){
      let result = await (this.apiGetUserRoles()).toPromise();
      localStorage.setItem("user-roles", JSON.stringify(Object.values(result)));
      return Object.values(result);
    }else{
      return userRoles;
    } 
    
  }

  private apiGetUserRoles(){

    return this.http.get(environment.apiHost + "/api/grouprights/employee");

  }

  getUserRoles(): Observable<any>{

    return  this.http.get(environment.apiHost + "/api/grouprights/employee");

  }





}
