import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-field-error-display',
  templateUrl: './field-error-display.component.html',
  styleUrls: ['./field-error-display.component.css']
})
export class FieldErrorDisplayComponent implements OnInit {

  txtMaxChars = "* Max characters reached. Use Comments for additional details."
  errMsgNumber = "* Field cannot be blank.";

  // @Input() errorMsg: string;
  @Input() displayError: boolean;
  @Input() control: FormControl;

  ngOnInit(): void {
    
    if(this.displayError === undefined){
      this.displayError = this.control.invalid;
    }

  }

}


