import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { HttpClient } from "@angular/common/http";
import { AuthService } from '../services/auth.service';
import { AuthModel } from '../models/auth-model';
import { RolesService } from '../services/roles.service';
import { Role } from '../enums/role.enums';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'dashboard'
    },
    {
        path: '/visits',
        title: 'Visits',
        type: 'sub',
        icontype: 'event_available',
        collapse: 'visits',
        children: [
            {path: 'visits', title: 'OQAT', ab:'O'},
            {path: 'followup', title: 'RQAT', ab:'R'},
        ]
    },
    {
        path: '/meetings',
        title: 'Reports',
        type: 'sub',
        icontype: 'summarize',
        collapse: 'meetings',
        children: [
            {path: 'meetings', title: 'OQAT', ab:'O'},
            {path: 'visited', title: 'RQAT', ab:'R'},
        ]
    },
    {
        path: '/support/oqat',
        title: 'Support',
        type: 'link',
        icontype: 'info'
    }
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html'
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ps: any;
    userFullName: string;
    isAdmin: boolean;
    isFinance: boolean;
    version: string;
    auth: any;
    roles: string[];
    billingMenu: RouteInfo

    constructor(private http:HttpClient, public authSrv: AuthService, private roleSrv: RolesService) {
        // this.userFullName = this.auth.fullName;
        // this.isAdmin = this.auth.isDev;
        // this.auth = new AuthModel(localStorage.getItem('sessionKey'))
        this.auth = this.authSrv.currentUser
     }
    
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    async ngOnInit() {

        this.userFullName = this.auth.fullName;
        this.isAdmin = this.auth.isDev;

        this.billingMenu =  {
            path: '/marketers',
            title: 'Admin',
            type: 'sub',
            icontype: 'summarize',
            collapse: 'marketers',
            children: [
                {path: 'marketers/billing', title: 'Billing', ab:'B'},
                {path: 'finance/collections', title: 'Collections', ab:'C'},
            ]
        };

        const devMenu =  {
            path: '/marketers',
            title: 'Dev',
            type: 'sub',
            icontype: 'integration_instructions',
            collapse: 'marketers',
            children: [
                {path: 'marketers/census', title: 'Census', ab:'C'},
                {path: 'patients-rcfe-list', title: 'RCFE List', ab: 'L'},
            ]
        };

       


        await this.authSrv.getUserRoles()
            .then((userRoles) => {
                this.roles = userRoles;
                this.isFinance = this.roles.includes(Role.FINANCE)

                let roleRoutes = ROUTES;
                
                if(this.isFinance) {
                    roleRoutes.splice(ROUTES.length -1, 0, this.billingMenu);
                }

                if(this.isAdmin){
                    roleRoutes.splice(ROUTES.length -1, 0, devMenu);
                }

                this.menuItems = roleRoutes.filter(menuItem => menuItem);

            })






        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }

        this.http.get("assets/docs/version.json")
        .subscribe(v => {
            this.version = v[0].version;
        })

       


    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    ngOnDestroy(){
        this.menuItems = [];
    }


}
