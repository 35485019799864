import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  private secretKey = 'hcproBurlway851';

  constructor() { }

  encrypt(text: string): string {
    const encrypted = CryptoJS.AES.encrypt(text, this.secretKey);
    return encrypted.toString();
  }

  decrypt(encryptedText: string): string {
    const decrypted = CryptoJS.AES.decrypt(encryptedText, this.secretKey);
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
