import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { AuthService } from "./services/auth.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { environment } from "../environments/environment";
import { Keepalive } from "@ng-idle/keepalive";

declare const $: any;

@Component({
  selector: "app-my-app",
  templateUrl: "./app.component.html",
  styles: [
    ".error-msg {color: red;display: inline-block;position: absolute;top: 34px;font-size: 75%;margin-top: 2.604167em;top: calc(100% - 1.72916667em);}",
  ],
})
export class AppComponent implements OnInit {
  private _router: Subscription;
  private idleSeconds: number = environment.idleSeconds;
  private timeoutSeconds: number = environment.timeoutSeconds;
  isIdle = false;
  modalFg: FormGroup;

  constructor(
    private router: Router,
    private _idle: Idle,
    public auth: AuthService,
    private fb: FormBuilder,
    keepalive: Keepalive,
    cd: ChangeDetectorRef
  ) {
    this._idle.setIdle(this.idleSeconds);
    this._idle.setTimeout(this.timeoutSeconds);
    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this._idle.onIdleStart.subscribe(() => {
      // show the modal
      // alert("Idle Started");

      $("#myModal").modal("show");
    });

    this._idle.onIdleEnd.subscribe(() => {
      // hide the modal

      cd.detectChanges();
    });
    this._idle.onTimeoutWarning.subscribe((secondsLeft: number) => {
      // Update the warning message
    });
    this._idle.onTimeout.subscribe(() => {
      // Hide the modal, log out, do something else

      $("#myModal").modal("hide");
      this.auth.logOut();
    });
  }

  ngOnInit() {
    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName("body")[0];
        const modalBackdrop =
          document.getElementsByClassName("modal-backdrop")[0];
        if (body.classList.contains("modal-open")) {
          body.classList.remove("modal-open");
          modalBackdrop.remove();
        }
      });

    this.modalFg = this.fb.group({
      pwd: [""],
    });

    this._idle.watch();
  }

  async continue() {
    let usercode = this.auth.currentUser.userCode;
    let password = this.modalFg.controls["pwd"];
    let credentials = {
      userName: usercode,
      password: password.value,
    };

    let isAuthenticated = await this.auth.idleRefreshTokens(credentials);

    if (isAuthenticated) {
      $("#myModal").modal("hide");
      password.reset();
    } else {
      password.setErrors({ incorrect: true });
    }
  }
}
