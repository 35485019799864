import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  IReportDashboardParams,
  IReportDashboardResponse,
} from "../interfaces/reportDashboard.interface";
import { CryptoService } from "./crypto.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root",
})
export class Gentrixv2Service {
  constructor(
    private http: HttpClient,
    private cryptoService: CryptoService,
    private jwtHelperService: JwtHelperService
  ) {}

  public get rights(): any {
    const data = this.getAuthData();
    return data.rights;
  }

  private getAuthData(): any {
    const authData = sessionStorage.getItem("authData");
    const decodedToken = this.jwtHelperService.decodeToken(authData);
    return decodedToken;
  }

  /**
   * Sends a login request to the API.
   * @param {IAuth} auth - The authentication data.
   * @returns {Observable<any>} - Returns an observable that resolves to the response from the API.
   */
  public apiUserLogin(auth: any): Observable<any> {
    const url = `${environment.gentrixBE_URL}/api/gentrix/v1/user/login`;
    return this.http.post(url, auth);
  }

  public authenticateUserGentrix(auth: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiUserLogin(auth).subscribe({
        next: (response) => {
          if (response.token) {
            const decoded = this.cryptoService.encrypt(response.token);
            sessionStorage.setItem("authData", decoded);
            sessionStorage.setItem("tokenGentrix", decoded);
            resolve(true);
          }
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  private apiGetCrmDropdown(): Observable<any> {
    const url = `${environment.gentrixBE_URL}/api/gentrix/v1/user/crm`;

    return this.http.get(url);
  }

  public getCrmList(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiGetCrmDropdown().subscribe({
        next: (response) => {
          const transformedData = response.data.map((item) => ({
            ...item,
            crmId: item.employeeId,
          }));
          localStorage.setItem("crmList", JSON.stringify(transformedData));
          resolve(transformedData);
        },
        error: (error) => {
          reject(new Error(error));
        },
      });
    });
  }

  private apiGetReferralAndAdmissionDetails(
    data: IReportDashboardParams
  ): Observable<any> {
    const url = `${environment.gentrixBE_URL}/api/gentrix/v1/referralAdmissionReport?agencyId=${data.agencyId}&crmId=${data.crmId}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`;

    return this.http.get(url);
  }

  public getReferralAndAdmissionDetails(
    data: IReportDashboardParams
  ): Promise<IReportDashboardResponse> {
    return new Promise<IReportDashboardResponse>((resolve, reject) => {
      this.apiGetReferralAndAdmissionDetails(data).subscribe({
        next: (response) => {
          resolve(response.body);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
}
