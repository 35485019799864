import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {


    constructor(private router: Router) {}
    
 canActivate() {
        let token = localStorage.getItem('jwt');

        if (token) {      
            return true;      
        }      
            // navigate to login page as user is not authenticated      
         this.router.navigate(['/pages/login']);      
            return false;      

    }



}


