import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "src/environments/environment";
import {
  AuthModel,
  IdleRefreshPayload,
  TokenResponse,
} from "../models/auth-model";
import { User } from "../models/user-model";
import { RolesService } from "./roles.service";
import { Gentrixv2Service } from "./gentrixv2.service";
import { CryptoService } from "./crypto.service";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  tokenString: string;
  isGentrixAuthenticated: boolean;

  constructor(
    private http: HttpClient,
    private crypto: CryptoService,
    private router: Router,
    private roleSrv: RolesService,
    private gentrixService: Gentrixv2Service
  ) {}
  async authenticateUser(credentials) {
    let isAuthenticated = false;
    let session;
    let request = this.http.post(
      environment.apiHost + "/api/auth/login",
      credentials
    );

    try {
      const gentrixCredentials = {
        idsid: credentials.username,
        password: credentials.password,
      };

      await this.gentrixService.authenticateUserGentrix(gentrixCredentials);

      this.isGentrixAuthenticated = this.gentrixService.rights;
    } catch (error) {
      console.log(error);
      this.isGentrixAuthenticated = null;
    }

    if (
      this.isGentrixAuthenticated === null ||
      this.isGentrixAuthenticated === true
    ) {
      try {
        let resPromise = await request.toPromise();
        const { id: sessionId, token, refreshToken } = resPromise["session"];

        sessionStorage.setItem("sessionId", sessionId);
        localStorage.setItem("sessionKey", token);
        localStorage.setItem("jwt", token);
        localStorage.setItem("refreshToken", refreshToken);

        if (resPromise["error"]) {
          session = "Exist";
        } else {
          isAuthenticated = true;
          session = "Created";
        }
      } catch (error) {
        isAuthenticated = false;
        console.log(error);
        if (error.status === 0) {
          session = "Disconnected";
        }
      }
    } else {
      console.log("Gentrix authentication failed.");
    }

    return { isAuthenticated: isAuthenticated, sessionStatus: session };
  }
  private async getRefreshTokens(
    url: string,
    payload: TokenResponse | IdleRefreshPayload
  ): Promise<boolean> {
    let isRefreshSuccess: boolean;

    const refreshRes = await new Promise<TokenResponse>((resolve, reject) => {
      this.http
        .post<TokenResponse>(url, payload, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        })
        .subscribe({
          next: (res: TokenResponse) => resolve(res),
          error: (_) => {
            reject;
            isRefreshSuccess = false;
          },
        });
    });

    localStorage.setItem("jwt", refreshRes.accessToken);

    if (url.includes("auth")) {
      localStorage.setItem("refreshToken", refreshRes.refreshToken);
    }

    isRefreshSuccess = true;
    return isRefreshSuccess;
  }

  public async idleRefreshTokens(
    credentials: IdleRefreshPayload
  ): Promise<boolean> {
    const accessTokenStored = localStorage.getItem("jwt");
    const refreshTokenStored = localStorage.getItem("refreshToken");
    const payload: IdleRefreshPayload = {
      ...credentials,
      accessToken: accessTokenStored,
      refreshToken: refreshTokenStored,
    };

    const url = environment.apiHost + "/api/auth/refresh";

    const refreshResult = await this.getRefreshTokens(url, payload);

    return refreshResult;
  }

  async logOut() {
    sessionStorage.removeItem("jwt");
    sessionStorage.setItem("isLoggedIn", "false");
    const sessionId = sessionStorage.getItem("sessionId");

    let request = await this.http.delete(
      environment.apiHost + "/api/sessions?sessionId=" + sessionId
    );
    request.subscribe(() => {
      // console.log("logged out");
    });
    sessionStorage.clear();
    localStorage.clear();

    window.location.reload();
    this.router.navigate(["/pages/login"]);
  }

  async forceLogout() {
    const sessionId = sessionStorage.getItem("sessionId");

    let request = await this.http.delete(
      environment.apiHost + "/api/sessions?sessionId=" + sessionId
    );
    request.subscribe(() => {
      // console.log("logged out");
    });
    this.router.navigate(["/pages/login"]);
  }

  public async tryRefreshingTokens(token: string) {
    const refreshToken: string = localStorage.getItem("refreshToken");
    if (!token || !refreshToken) {
      return false;
    }

    const payload: TokenResponse = {
      accessToken: token,
      refreshToken: refreshToken,
    };
    const url = environment.apiHost + "/api/token/refresh";
    // const isRefreshSuccess = await this.getRefreshTokens(url, payload);

    // return isRefreshSuccess;

    return this.http
      .post<TokenResponse>(url, payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .subscribe((res) => {
        localStorage.setItem("jwt", res.accessToken);
        // localStorage.setItem("refreshToken", res.refreshToken);
      });
  }

  public get currentUser(): User {
    const user = new AuthModel(localStorage.getItem("sessionKey"));
    return user.currentUser;
  }

  public async getUserRoles() {
    let roles: string[] = await this.roleSrv.getUserRoles().toPromise();
    return roles;
  }
}
