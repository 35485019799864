import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth-guard.service';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [
      { path: '', loadChildren: './dashboard/dashboard.module#DashboardModule' },
      { path: '', loadChildren: './hcpro/hcpro.module#HcproModule' },
      { path: '', loadChildren: './oqat/oqat.module#OqatModule' },
      { path: '', loadChildren: './timeline/timeline.module#TimelineModule' },
      { path: '', loadChildren: './patients/patients.module#PatientsModule' },
      { path: '', loadChildren: './episodes/episodes.module#EpisodesModule' },
      { path: '', loadChildren: './covid/covid.module#CovidModule' },
      { path: '', loadChildren: './employee/employee.module#EmployeeModule' },
      { path: '', loadChildren: './followup/followup.module#FollowupModule' },
      { path: '', loadChildren: './referrals/referrals-routing.module#ReferralsRoutingModule' },
      { path: '', loadChildren: './marketers/marketers.module#MarketersModule' },
      { path: '', loadChildren: './finance/finance.module#FinanceModule' },
      { path: '', loadChildren: './patient-rcfe/patient-rcfe.module#PatientRcfeModule' },
    ],
    canActivate: [AuthGuard]
  }, {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: 'pages',
      loadChildren: './pages/pages.module#PagesModule'
    }]
  }
];
